import React from "react";
import Chat from "./Chat.jsx";
import ChatVmix from "./ChatVmix.jsx";
import logo from "./img/VERSIONE-BIANCO.svg";

document.toggledFullScreen = false;
// import Player from "./Player.jsx";

function render(parent) {
  if (
    !parent.state.players ||
    !parent.state.playerSettings ||
    !parent.state.players[0]
  )
    return null;
  return (
    <React.Fragment>
      <div className="container vmix">
        {parent.state.hasChat ? (
          <ChatVmix
            channel={parent.state.channel}
            chatExpire={parent.state.chatExpire}
            hasAudio={parent.state.hasAudio}
            hasChat={parent.state.hasChat}
            userId={parent.state.userId}
            userName={parent.state.userName}
            muted={parent.state.mode === "muted"}
            messages={parent.state.messages}
            sendMessage={(m) => {
              parent.sendMessage(m);
            }}
            deleteMessage={(m) => {
              parent.deleteMessage(m);
            }}
            parent={parent}
          />
        ) : null}

        {/* <div className="row justify-content-center">
          <div className="col-auto">
            {parent.state.legacyMode ? (
              <p className="mb-3 mt-1 toggle-mode">
                Vuoi tornare alla visualizzazione standard?{" "}
                <strong
                  className="pointer"
                  onClick={(e) => {
                    parent.setState({
                      legacyMode: !parent.state.legacyMode,
                    });
                  }}
                >
                  clicca qui
                </strong>
              </p>
            ) : (
              <p className="mb-3  mt-1 toggle-mode">
                Hai problemi nel visualizzare il video?{" "}
                <strong
                  className="pointer"
                  onClick={(e) => {
                    parent.setState({
                      legacyMode: !parent.state.legacyMode,
                    });
                  }}
                >
                  clicca qui
                </strong>
              </p>
            )}
          </div>
        </div> */}
      </div>
    </React.Fragment>
  );
}

function populatePlayers(
  players,
  settings,
  eventId,
  legacyMode,
  updateRotation
) {
  const styles = [
    {},
    { transform: "rotate(90deg) scale(55%)" },
    { transform: "rotate(180deg)" },
    { transform: "rotate(270deg) scale(55%)" },
  ];

  let currentStyles = localStorage.getItem(eventId + "-rotations");
  if (currentStyles) {
    currentStyles = JSON.parse(currentStyles);
  } else {
    currentStyles = {};
    for (let player of players) currentStyles[player.id] = 0;
  }

  if (!players || !settings) return null;
  const playerPari = players.length % 2 === 0;
  return (
    <div className="row  justify-content-center mb-0 mb-sm-3 mt-0 mt-sm-4">
      {players.map((player, key) => {
        const streamName = `${eventId}-${player.id}`;
        let style = 0;
        if (currentStyles && currentStyles[player.id])
          style = currentStyles[player.id];

        if (players.length == 1)
          return (
            <>
              <div className="col-12 col-lg-10 chat-container" key={key}>
                <div className="col-12 mt-3 mb-4 mt-xl-0 mb-xl-0"></div>
                <div className="iframe-container">
                  <iframe
                    style={styles[style]}
                    src={
                      "https://dio21.streamset.pro/streamset/play.html?name=" +
                      streamName
                    }
                    title={player.name}
                    frameBorder="0"
                    allowFullScreen
                  ></iframe>
                </div>
              </div>
            </>
          );

        if (!playerPari && key === 0)
          return (
            <>
              <div className="col-md-3" key={key + 100}></div>
              <div className="col-md-6" key={key}>
                <div className="col-12 mt-3 mb-2">
                  {player.name}{" "}
                  <span
                    className="rotate-btn"
                    onClick={() => {
                      currentStyles[player.id] = style + 1;
                      if (currentStyles[player.id] > 3)
                        currentStyles[player.id] = 0;
                      localStorage.setItem(
                        eventId + "-rotations",
                        JSON.stringify(currentStyles)
                      );
                      updateRotation({ rotations: style });
                    }}
                  >
                    <i className="fas fa-sync ml-3"></i> rotate
                  </span>
                </div>
                <div className="iframe-container">
                  <iframe
                    style={styles[style]}
                    src={
                      "https://dio21.streamset.pro/streamset/play.html?name=" +
                      streamName
                    }
                    title={player.name}
                    frameBorder="0"
                    allowFullScreen
                  ></iframe>
                </div>
              </div>
              <div className="col-md-3" key={key + 200}></div>
            </>
          );

        return (
          <div className="col-md-6" key={key}>
            <div className="col-12 mt-3 mb-2">
              {player.name}{" "}
              <span
                className="rotate-btn"
                onClick={() => {
                  currentStyles[player.id] = style + 1;
                  if (currentStyles[player.id] > 3)
                    currentStyles[player.id] = 0;
                  localStorage.setItem(
                    eventId + "-rotations",
                    JSON.stringify(currentStyles)
                  );
                  updateRotation({ rotations: style });
                }}
              >
                <i className="fas fa-sync ml-3"></i> rotate
              </span>
            </div>
            <div className="iframe-container">
              <iframe
                style={styles[style]}
                src={
                  "https://dio21.streamset.pro/streamset/play.html?name=" +
                  streamName
                }
                title={player.name}
                frameBorder="0"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        );
      })}
    </div>
  );
}
export default render;
