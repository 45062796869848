import React from "react";
import "./Chat.css";

class ChatVmix extends React.Component {
  messagesEnd = null;
  messagesFrame = null;

  constructor(props) {
    super(props);
    this.state = {
      pendingMessage: "",
    };
  }

  scrollToBottom = () => {
    //this.messagesEnd.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
    var scrollOptions = {
      left: 0,
      top: 1000000000,
      behavior: "smooth",
    };

    if (this.messagesFrame) this.messagesFrame.scrollTo(scrollOptions);
  };

  componentDidMount() {
    this.scrollToBottom();
  }

  componentDidUpdate() {
    this.scrollToBottom();
  }

  keyDown(event) {
    if (event.keyCode === 13) {
      this.sendMessage();
    }
  }

  sendMessage() {
    const msg = this.state.pendingMessage;

    if (msg === "enter-mode-chat") {
      this.setState({ pendingMessage: "" });
      this.props.parent.setFullChat();
      return;
    }

    if (msg === "enter-mode-moderator") {
      this.setState({ pendingMessage: "" });
      this.props.parent.setModerator();
      return;
    }

    if (msg === "enter-mode-vmix") {
      this.setState({ pendingMessage: "" });
      this.props.parent.setVmix();
      return;
    }

    this.setState({ pendingMessage: "" });

    this.props.sendMessage(msg);
  }

  render() {
    if (!this.props.hasAudio)
      return (
        <React.Fragment>
          <div
            className="row  justify-content-center mb-0 mb-0 mt-0 mt-0"
            id="chat"
          >
            <div className="col-12 col-lg-10 chat-container">
              <div className="row p-3" id="chatbox">
                <div className="card col-12 p-0">
                  <div
                    id="messages-vmix"
                    className={
                      this.props.parent.state.fullChat
                        ? "card-body p-1 m-0 full"
                        : "card-body p-1 m-0"
                    }
                    ref={(el) => {
                      this.messagesFrame = el;
                    }}
                  >
                    {this.props.messages.map((message, key) => {
                      return (
                        <div
                          className={
                            message.isMe
                              ? "card general-message msgBoxMyself"
                              : "card general-message msgBoxOthers"
                          }
                          key={key}
                        >
                          {(this.props.parent.state.moderator ||
                            message.isMe) && (
                            <div
                              className="delete-message"
                              onClick={() => {
                                console.log(message);
                                this.props.deleteMessage(message);
                              }}
                            >
                              x
                            </div>
                          )}
                          <div className="msgBoxUsername">
                            {message.userName}
                          </div>
                          <div className="msgBoxBody">
                            {message.isFile ? (
                              <a href={message.messaggio} target="null">
                                <i
                                  className="fas fa-download"
                                  style={{ marginRight: "14px" }}
                                ></i>
                                {message.title}
                              </a>
                            ) : (
                              message.messaggio
                            )}
                          </div>
                          <div className="msgBoxDate">{message.time}</div>
                        </div>
                      );
                    })}

                    <div
                      style={{ float: "left", clear: "both" }}
                      ref={(el) => {
                        this.messagesEnd = el;
                      }}
                    ></div>
                  </div>
                </div>
              </div>

              <div
                className="row px-3 pb-sm-4"
                id="messageBox"
                onKeyDown={(e) => {
                  this.keyDown(e);
                }}
              ></div>
            </div>
          </div>
        </React.Fragment>
      );
  }
}

//   channel={this.props.channel}
//   chatExpire={this.props.chatExpire}
//   hasAudio={this.props.hasAudio}
//   hasChat={this.props.hasChat}
//   userId={this.props.userId}
//   userName={this.props.userName}

export default ChatVmix;
